<template>
  <main v-if="orders">
    <div
            class="order-title info-title"
            @click="switchExpend(0, expendSwitch[0])"
    >
      订单详情
      <img    class="expend"  :class="expendSwitch[0] ? 'expended' : ''"
              src="@/assets/img/expend.png"
              alt=""/>
    </div>
    <ul class="order-ul" v-show="expendSwitch[0]">
      <li>
        <div class="order-date">订单编号：{{orders.ono}}</div>
        <div class="order-date">订单金额：<b style="color:#df0024">￥{{orders.oin}}</b></div>
        <div class="order-date">跟&ensp;单&ensp;人：{{orders.ocontact}}/{{orders.tel}}</div>
        <div class="order-date">下单时间：{{orders.odate}}</div>
      </li>
    </ul>
    <div class="goods-info-wapper">
      <div class="goods-wapper">
        <div class="info-title" @click="switchExpend(1, expendSwitch[1])">
          商品信息
          <img
                  class="expend"
                  :class="expendSwitch[1] ? 'expended' : ''"
                  src="@/assets/img/expend.png"
                  alt=""
          />
        </div>
        <ul class="product-module" v-show="expendSwitch[1]">
          <li class="product-li" v-for="(item, index) in details" :key="index">
            <a>
              <div
                      class="add-car"
                      style="right: 11.738px; width: 40.5px; padding: 0"
              >
                <span>X {{ item.amount }}</span>
              </div>
              <div class="product-title">{{ item.cnname }}</div>
              <div class="product-img">
                <img :src="item.pic || 'https://beinabncc.oss-cn-beijing.aliyuncs.com/News/14b5205366e645649e9499501adcf284.png'" alt="" />
              </div>

              <div class="product-info">
                <span style="color: transparent">X {{ item.retail_price }}</span>
              </div>
              <div class="product-brand">
                <p v-if="item.spec">
                  <span>{{ item.catno }}</span>
                  , &nbsp;
                  <span>{{ item.spec }}</span>
                </p>
                <p v-else>
                  <span>{{ item.catno }}</span>
                </p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="logistics-info-wapper">
      <div class="info-title" @click="switchExpend(2, expendSwitch[2])">
        配送信息
        <img
                class="expend"
                :class="expendSwitch[2] ? 'expended' : ''"
                src="@/assets/img/expend.png"
                alt=""
        />
      </div>

      <div v-show="expendSwitch[2]" class="wapper">
        <div class="order-logistics" >
          <p><span>收&ensp;货&ensp;人：{{ orders.username }}</span></p>
          <p><span>电&emsp;&emsp;话：{{ orders.mobile }}</span></p>
          <p style="white-space: normal"><span style="width: 98%;line-height:26px;">详细地址：{{ orders.post_address }}</span></p>
          <p v-if="orders.postcount&&orders.postcount>0">
            <span class="order-logistics-title">物流信息：<a class="order-view-logistics" @click="confirmSub">查看物流</a></span>
          </p>
          <p v-else-if="orders.opay&&(orders.opay=='货到付款' || orders.opay=='公司未到' || orders.opay=='分期付款' || orders.opay=='先寄发票')">
            <span class="order-logistics-title">状&emsp;&emsp;态：待付款</span>
          </p>
          <p v-else>
            <span class="order-logistics-title">状&emsp;&emsp;态：待发货<a class="update_order_address" @click="toUpdateOrderAddress">修改地址</a></span>
          </p>

        </div>
        <div class="logistics" v-for="(item, index) in orderpost" :key="index">
          <p>配送方式：{{ item.shipperName }}</p>
          <p>快递单号：{{ item.logisticCode }}</p>

          <p v-if="item.reason">
            <span style="color: red">{{ item.reason }}</span>
          </p>

          <div v-else class="nei-order-wuliu">
            <div class="wuliu-detail" @click.once="onViewWuliu(index)">
              快递详情
            </div>
            <ul v-if="item.kd">
              <li v-for="(itm, i) in item.kd" :key="i">
                <span class="orderDetail_accept">{{ dateFormat(itm.acceptTime) }}{{ itm.acceptStation }}</span>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="returnMsg"  class="logistics">
          <p>
            <span style="color: red">暂无物流信息</span>
          </p>
        </div>
      </div>
    </div>
  </main>
  <loading :options="loadOption" v-if="loading"></loading>
</template>

<script lang="ts">
  import {defineComponent, inject, ref} from "vue";
  import { useRoute } from "vue-router";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import dateFormat from "@/utils/dateFormat";
  import { calendarFormat } from "@/utils/dateFormat";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import verificationCode from "@/components/common/verificationCode.vue";
  import router from "@/router";
  // import Modal from "@/components/UI/Modal";
  interface CustomObject {
    [key: string]: any;
  }
  export default defineComponent({
    name: "NeiOrderDetail",
    components: {
      Loading,
      // verificationCode
    },
    setup() {
      const orders = ref<CustomObject | null>(null);
      const details = ref([]);
      const saleOrderId = ref([]);
      const hasSaleOrder = ref([]);
      const returnMsg = ref(false);
      const orderpost = ref<CustomObject[]>([]);
      const route = useRoute();
      const id = route.query.id;
      const loading = ref(true);
      const topicList = ref(null);
      const expendSwitch = ref([true, true, false]);
      const showVerification = ref(false)
      const userInfo = inject("userInfo") as any;
      const ono = ref();
      const postId = ref();
      axios
              .get("/M/User/NeiOrderDetail", {
                params: {
                  ONO: id,
                  validate: true,
                },
              })
              .then((res) => {
                if (res.data.success) {
                  orders.value = res.data.obj.orders;
                  ono.value =res.data.obj.orders.ono;
                  postId.value =res.data.obj.orders.postid;
                  details.value = res.data.obj.Details;
                  hasSaleOrder.value = res.data.obj.hasSaleOrder;
                  saleOrderId.value = res.data.obj.saleOrderId;
                  topicList.value = res.data.obj.orderTopicList;
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
                loading.value = false;
              })
              .catch((e) => {
                Toast({
                  type: "error",
                  title: e.message || e,
                });
                loading.value = false;
              });
      const loadOption = {
        text: "加载中...",
        color: "#df0024",
        textColor: "#df0024",
      };

      // function onViewWuliu(opost: string, omail: string, index: number) {
      //   axios
      //     .get("/M/User/expressDetail", {
      //       params: {
      //         opost,
      //         omail,
      //         mobile: orders.value?.mobile,
      //       },
      //     })
      //     .then((res) => {
      //       if (res.data.success) {
      //         orderpost.value[index].kd = res.data.obj.traces;
      //       } else {
      //         Toast({
      //           type: "error",
      //           title: res.data.msg,
      //         });
      //       }
      //     })
      //     .catch((e) => {
      //       Toast({
      //         type: "error",
      //         title: e.message || e,
      //       });
      //       loading.value = false;
      //     });
      // }
      function onViewWuliu(index: any) {
        orderpost.value[index].kd = orderpost.value[index].traces
        // console.log(orderpost.value, '9999999999999999999')
      }
      function switchExpend(index: number, value: boolean) {
        expendSwitch.value[index] = !value;
      }
      function hasSaleOrderTip() {
        router.push({
          name: "SaleOrderDetail",
          query: {
            id: saleOrderId.value,
          },
        });

        // Toast({
        //   title: '当前订单有未解决的工单',
        //   type: "error",
        //   duration:2000,
        //   onClose: () => {
        //     router.push({
        //       name: "SaleOrderDetail",
        //       query: {
        //         id: saleOrderId.value,
        //       },
        //     });
        //   },
        // });
        // return false
      }
      // function viewDetail() {
      //   showVerification.value = true
      // }
      // function onClose() {
      //   showVerification.value = false
      // }
      function confirmSub(code: any) {
        // if (!code.value) {
        //   Toast({
        //     title: '请输入验证码！',
        //     type: "error",
        //   });
        //   return false
        // }

        const formData = new FormData();
        formData.append("ono", (orders.value as any).ono);
        formData.append("code", code.value);
        axios.post("/M/User/neiOrderExpressTrace", formData)
                .then((res) => {
                  if (res.data.success) {
                    // showVerification.value = false
                    orderpost.value = res.data.obj
                    if(res.data.obj&&res.data.obj.length<1){
                      returnMsg.value =true;
                    }
                  } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                    });
                  }
                })
                .catch(() => {
                  // Toast({
                  //     title: '未知错误，请联系客服',
                  //     type: "success"
                  // })
                });
      }
      function toUpdateOrderAddress() {
        console.log("postId.value",postId.value)
        console.log("ono.value",ono.value)
        if(postId.value && ono.value){
          router.push({
            name: "updateAddressOfOrder",
            query: {
              postId: postId.value,
              ono: ono.value,
            },
          });
        }else{
          Toast({
            title: '信息不全，无法修改',
            type: "error",
          });
          return false
        }

      }
      return {
        orders,
        dateFormat,
        details,
        orderpost,
        loading,
        loadOption,
        topicList,
        saleOrderId,
        hasSaleOrder,
        returnMsg,
        onViewWuliu,
        expendSwitch,
        switchExpend,
        hasSaleOrderTip,
        calendarFormat,
        toUpdateOrderAddress,
        // viewDetail,
        // showVerification,
        // onClose,
        confirmSub,
        userInfo,
        ono,
        postId,
      };
    },
  });
</script>

<style lang="scss" scoped>
  main {
    padding-bottom: 109.987px;
    width: 355.013px;
    margin: 0 auto;
  }

  /**

      菜单导航
    */
  main{
    color:#333;
  }
  .menu-nav {
    overflow: hidden;
    height: 45px;
    line-height: 45px;
    border-bottom: 6.487px solid #f4f4f4;
  }

  .menu-nav li {
    float: left;
    width: 50%;
    text-align: center;
  }
  .menu-nav li a {
    display: block;
    text-align: center;
    line-height: 45px;
    width: 100%;
    font-size: 16.012px;
    color: #666;
  }
  .menu-nav .active a {
    color: #df0024;
  }

  .order-ul li {
    box-sizing: border-box;
    padding: 0 15px;
    /*padding-bottom: 15px;*/
    overflow: hidden;
    /*border-bottom: 5.625px solid #f4f4f4;*/
    position: relative;

    /*padding-left: 70px;*/
  }

  .order-ul .title {
    width: 100%;
    height: 40.012px;
    line-height: 40.012px;
    border-bottom: 1px solid #eee;
    font-size: 15px;
    color: #444;
    position: relative;
  }
  .price {
    position: absolute;
    right: 0;
    top: 0;
    height: 40.012px;
    line-height: 40.012px;
    color: rgb(102, 102, 102);
  }
  .order-date {
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 10.013px;

    font-size: 15px;
    box-sizing: border-box;
    color: #666;
    position: relative;
  }
  .order-btn {
    text-align: center;
    width: 79.987px;
    height: 22.5px;
    border-radius: 15px;
    float:right;
    line-height: 22.5px;
    font-size: 13.988px;
    color: #df0034;
    border: solid 1px #df0024;
    margin-top:-5px;
    margin-right: 18px;
  }

  .balance {
    position: fixed;
    color: #666;
    bottom: 49.987px;
    left: 0;
    right: 0;
    height: 49.987px;
    line-height: 49.987px;
    padding-left: 10.013px;
    border-top: 1.012px solid #f4f4f4;
    border-bottom: 1.012px solid #f4f4f4;
    font-size: 18px;
    font-weight: bold;
    background-color: #fff;
    z-index: 100;
  }

  .empty {
    padding-top: 160.013px;
    text-align: center;
    padding-bottom: 49.987px;

    color: #9f9f9f;
    font-size: 13.012px;
  }
  .empty img {
    width: 132.487px;
    height: 103.988px;
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .empty a {
    font-size: 13.012px;
    color: #df0024;
  }

  .order-title {
    color: #52ba65;
    padding-left: 15px;
    font-size: 18px;
  }
  .goods-wapper,
  .logistics-info-wapper {
    width: 355.013px;
    margin: 0 auto;
  }

  .info-title {
    color: #444444;
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 7px;
    .expend {
      width: 16px;
      height: auto;
      vertical-align: middle;
      position: relative;
      top: -1px;
      transform: rotateZ(0);
      transition: transform 0.5s;
    }
    .expended {
      position: relative;
      top: -1px;
      transform: rotateZ(-180deg);
      transition: transform 0.5s;
    }
  }
  .info-title::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 3px;
    height: 14px;
    transform: translateY(-50%);
    background-color: #aa183c;
  }
  .goods-info-wapper img {
    width: 90px;
    height: 90px;
    vertical-align: top;
  }
  .good-info {
    display: inline-block;
    padding-top: 4.988px;
    width: 250.012px;
  }
  .good-info p {
    color: #444444;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 4.988px;
    margin: 0;
  }
  .logistics-info-wapper p {
    color: #666666;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 10px;
    margin: 0;
    /*margin-bottom: 15px;*/
  }
  .logistics {
    position: relative;
    font-size: 14px;
    line-height: 25px;
    margin-top:10px;
    padding-left:20px;
  }
  .logistics a {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    width: 85.013px;
    height: 28.012px;
    border-radius: 13.988px;
    border: solid 1px #cccccc;
    line-height: 28.012px;
    text-align: center;
    color: #666666;
    font-size: 15px;
  }

  /* 产品列表 */

  .product-module .product-li {
    width: 100%;
    padding: 0.4rem 0.267rem;
    box-sizing: border-box;
    font-size: 14px;
    color: #999;
    padding-left: 2.267rem;
    position: relative;
    border-bottom: 0.027rem solid #f4f4f4;
    position: relative;
  }

  .product-module .product-img {
    position: absolute;
    left: 0.267rem;
    top: 0.5rem;
  }

  .product-module .product-img img {
    width: 1.733rem;
    height: 1.733rem;
    object-fit: cover;
  }

  .product-module .product-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 0.427rem;
    color: #333;
    line-height: 1.5;
    min-height: 0.587rem;
    padding-right: 1.13rem;
    box-sizing: border-box;
    margin-bottom: 0.133rem;
    position: relative;
    margin-top: 0.05rem;
  }


  .product-module .product-info {
    font-size: 0.373rem;
    color: #999;
    line-height: 0.427rem;
    margin-bottom: 0.2rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }

  .product-module .product-brand {
    color: #999;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .product-module .product-brand span {
    padding-right: 0.133rem;
    font-size: 0.373rem;
    color: #999;
    line-height: 0.427rem;
  }
  .product-module .add-car {
    position: absolute;
    right: 0.613rem;
    top: 0.5rem;
    width: 0.44rem;
    height: 0.413rem;
    z-index: 2;
    padding: 0.267rem;
    padding-top: 0;
  }
  .product-module .add-car img {
    width: 100%;
    height: 100%;
  }
  .nei-order-wuliu li {
    color: #444444;
    font-size: 14px;
    margin-bottom: 0.467rem;
    line-height: 0.7rem;
  }
  .wuliu-detail {
    position: absolute;
    right: 15px;
    top: 0;
    text-align: center;
    width: 6em;
    padding: 0 10px;
    height: 22.5px;
    border-radius: 15px;
    line-height: 22.5px;
    font-size: 14px;
    color: #fff;
    border: solid 1px #3c3c3c;
    background-color: #3c3c3c;
  }
  .spend-date {
    position: absolute;
    left: 0px;
    top: 20px;
    color: #666;
  }
  .spend-date span {
    font-size: 22px;
    text-align: center;
    display: block;
  }
  .spend-date p {
    font-size: 14px;
    border-top: 1px solid #e5e5e5;
    padding-top: 5px;
  }
  .underline {
    // text-decoration: underline;
    color: rgb(61, 194, 214);
  }
  .view-detail {
    padding: 3px 10px;
    font-size: 0.4rem;
    border-radius: 45px;
    color: #fff;
    background-color: #aa183c;
    border: solid 0.02667rem #aa183c;
    cursor: pointer;
    float: right;
    position: relative;
    top: -4px;
    margin-right: 15px;
  }
  .order-deliver{
    font-size: 14px;
    color:#df0024;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-bottom: 6px solid #f4f4f4;
  }
  .order-expend{
    padding-bottom: 20px;
    border-bottom: 6px solid #f4f4f4;
  }
  .order-expend span{
    display: inline-block;
    font-size: 14px;
    width:50%;
    padding-left:20px;
  }
  .order-expend span:last-child{
    width:100%;
  }
  .order-logistics{
    /*margin-top:20px;*/
    border-bottom: 6px solid #f4f4f4;
    padding-bottom: 20px;
  }
  .order-logistics span{
    display: inline-block;
    font-size: 15px;
    width:45%;
    padding-left:20px;
  }
  .order-logistics-title{
    width:100%!important;
  }
  .order-view-logistics{
    display: inline-block;
    width:80px;
    height: 0.6rem;
    /*background:#ffeded;*/
    text-align: center;
    line-height: 20px;
    font-size: 0.37301rem;
    color: #df0034;
    border: solid 0.02667rem #df0024;
    border-radius: 0.53333rem;
  }
  .update_order_address{
    display: inline-block;
    margin-left: 20px;
    width:80px;
    height: 0.6rem;
    text-align: center;
    line-height: 20px;
    font-size: 0.37301rem;
    color: #df0034;
    border: solid 0.02667rem #df0024;
    border-radius: 0.53333rem;
  }
  .order-product{
    border-bottom: 6px solid #f4f4f4;
    padding-bottom: 20px;
  }
  .order-product p{
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left:10px;
  }
  .order_retail_price{
    color:#df0024!important;
    float:right;
  }
  .order-shipment{
    display: inline-block;
    width: 20px;
    height: 20px;
    /*border: 1px solid red;*/
    vertical-align: middle;
    margin-right: 10px;
    background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/ecf394b94150411c40b96c16f77e35b3.png")no-repeat;
  }
  .order-tobeshipment{
    display: inline-block;
    width: 20px;
    height: 20px;
    /*border: 1px solid red;*/
    vertical-align: middle;
    margin-right: 10px;
    background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/94154d9d82a29d333b6b004953b2c3cc.png")no-repeat;
  }
</style>
